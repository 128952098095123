<script setup>
const hasRecaptcha = recaptchaEnabled in window && recaptchaEnabled
</script>
<template>
  <div v-if="hasRecaptcha" class="flex items-center justify-center mt-2">
    <p class="text-center">
      <small class="text-gray-500" style="font-size:60%">
        <em>(Protected by reCAPTCHA, Google's
          <a
            class="text-gray-800"
            href="https://policies.google.com/privacy"
          >Privacy Policy</a>
          and
          <a
            class="text-gray-800"
            href="https://policies.google.com/terms"
          >Terms of Service</a> apply)</em>
      </small>
    </p>
  </div>
</template>
